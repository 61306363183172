<script setup>

</script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.6702 9.05914C9.89627 9.05914 10.8902 8.06521 10.8902 6.83914C10.8902 5.61307 9.89627 4.61914 8.6702 4.61914C7.44412 4.61914 6.4502 5.61307 6.4502 6.83914C6.4502 8.06521 7.44412 9.05914 8.6702 9.05914Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M14.97 9.05914C16.1961 9.05914 17.19 8.06521 17.19 6.83914C17.19 5.61307 16.1961 4.61914 14.97 4.61914C13.7439 4.61914 12.75 5.61307 12.75 6.83914C12.75 8.06521 13.7439 9.05914 14.97 9.05914Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M21.3596 9.05914C22.5857 9.05914 23.5796 8.06521 23.5796 6.83914C23.5796 5.61307 22.5857 4.61914 21.3596 4.61914C20.1336 4.61914 19.1396 5.61307 19.1396 6.83914C19.1396 8.06521 20.1336 9.05914 21.3596 9.05914Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M14.97 17.0982C16.1961 17.0982 17.19 16.1043 17.19 14.8782C17.19 13.6521 16.1961 12.6582 14.97 12.6582C13.7439 12.6582 12.75 13.6521 12.75 14.8782C12.75 16.1043 13.7439 17.0982 14.97 17.0982Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M14.97 25.3795C16.1961 25.3795 17.19 24.3855 17.19 23.1595C17.19 21.9334 16.1961 20.9395 14.97 20.9395C13.7439 20.9395 12.75 21.9334 12.75 23.1595C12.75 24.3855 13.7439 25.3795 14.97 25.3795Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M8.63992 25.3795C9.86599 25.3795 10.8599 24.3855 10.8599 23.1595C10.8599 21.9334 9.86599 20.9395 8.63992 20.9395C7.41385 20.9395 6.41992 21.9334 6.41992 23.1595C6.41992 24.3855 7.41385 25.3795 8.63992 25.3795Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M8.66965 9.08984L8.63965 20.9398" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M12.7496 14.8789H8.63965" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M14.9697 12.6598V9.08984" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M14.9697 17.1289V20.9389" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.2197 14.8789H21.3597" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M21.3604 9.05859V14.8786" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
  </svg>

</template>
