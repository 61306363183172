<script setup>

</script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9896 19.1104C21.7496 18.9604 21.5096 18.9004 21.2396 18.9004C20.7896 18.9004 19.7396 19.1404 19.0496 21.1804C17.8196 20.6104 16.4096 20.3104 14.9696 20.3104C13.5296 20.3104 12.1196 20.6104 10.8896 21.1804C10.1996 19.1404 9.1496 18.9004 8.6996 18.9004C8.4296 18.9004 8.1896 18.9604 7.9496 19.1104C6.9596 19.6804 6.77959 21.1804 7.4396 23.6704C7.5896 24.2704 7.7696 24.8104 7.9796 25.2904C8.0396 25.4404 8.18959 25.5304 8.33959 25.5304H21.5996C21.7496 25.5304 21.8996 25.4404 21.9596 25.2904C22.1696 24.7804 22.3496 24.2404 22.4996 23.6704C23.1296 21.1804 22.9796 19.7104 21.9896 19.1104ZM21.7496 23.4904C21.6296 23.9404 21.4796 24.3604 21.3296 24.7804H8.6096C8.4596 24.3904 8.3096 23.9704 8.1896 23.4904C7.6496 21.4504 7.70959 20.1904 8.33959 19.8004C8.45959 19.7404 8.5796 19.7104 8.6996 19.7104C9.1796 19.7104 9.8396 20.2804 10.2596 21.8704C10.2896 21.9904 10.3796 22.0804 10.4696 22.1404C10.5896 22.2004 10.7096 22.1704 10.7996 22.1404C11.9996 21.4804 13.4396 21.1504 14.9396 21.1504C16.4396 21.1504 17.8796 21.4804 19.0796 22.1404C19.1996 22.2004 19.3196 22.2004 19.4096 22.1404C19.5296 22.0804 19.5896 21.9904 19.6196 21.8704C20.0396 20.2804 20.6996 19.7104 21.1796 19.7104C21.2996 19.7104 21.4196 19.7404 21.5396 19.8004C22.1996 20.1604 22.2596 21.4504 21.7496 23.4904Z" fill="#82201E"/>
    <path d="M12.4195 12.9599C12.2695 12.8099 11.9995 12.8399 11.8795 12.9899C11.7295 13.1399 11.7295 13.4099 11.9095 13.5599C11.9395 13.5599 13.4395 15.0299 13.4395 19.0199C13.4395 19.2299 13.6195 19.4099 13.8295 19.4099C14.0395 19.4099 14.2195 19.2299 14.2195 19.0199C14.2195 14.6399 12.5095 13.0199 12.4195 12.9599Z" fill="#82201E"/>
    <path d="M17.5197 12.9613C17.4597 13.0213 15.7197 14.6413 15.7197 19.0213C15.7197 19.2313 15.8997 19.4113 16.1097 19.4113C16.3197 19.4113 16.4997 19.2313 16.4997 19.0213C16.4997 15.0313 17.9997 13.5613 18.0297 13.5613C18.1797 13.4113 18.2097 13.1713 18.0597 13.0213C17.9397 12.8413 17.6697 12.8113 17.5197 12.9613Z" fill="#82201E"/>
    <path d="M9.53914 18.0909C9.74914 18.0909 9.92914 17.9109 9.92914 17.7009V12.2409C9.92914 10.9509 10.9791 9.93086 12.2391 9.93086H17.6991C18.9891 9.93086 20.0091 10.9809 20.0091 12.2409V17.7009C20.0091 17.9109 20.1891 18.0909 20.3991 18.0909C20.6091 18.0909 20.7891 17.9109 20.7891 17.7009V12.2409C20.7891 10.7109 19.6791 9.45086 18.2391 9.18086C18.4191 8.91086 18.5391 8.58086 18.5391 8.22086V6.84086C18.5391 5.91086 17.7891 5.13086 16.8291 5.13086H13.0791C12.1491 5.13086 11.3691 5.88086 11.3691 6.84086V8.22086C11.3691 8.58086 11.4891 8.91086 11.6691 9.18086C10.2291 9.45086 9.11914 10.7109 9.11914 12.2409V17.7009C9.14914 17.9109 9.32914 18.0909 9.53914 18.0909ZM12.1791 6.84086C12.1791 6.33086 12.5991 5.91086 13.1091 5.91086H16.8591C17.3691 5.91086 17.7891 6.33086 17.7891 6.84086V8.22086C17.7891 8.73086 17.3691 9.15086 16.8591 9.15086H13.0791C12.5691 9.15086 12.1491 8.73086 12.1491 8.22086V6.84086H12.1791Z" fill="#82201E"/>
  </svg>

</template>
