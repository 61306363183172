<script setup>

</script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4098 20.4901C10.4098 21.5701 9.53977 22.4401 8.45977 22.4401C7.37977 22.4401 6.50977 21.5701 6.50977 20.4901C6.50977 19.4101 8.45977 16.8301 8.45977 16.8301C8.45977 16.8301 10.4098 19.4101 10.4098 20.4901Z" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M8.45953 21.1802C8.06953 21.1802 7.76953 20.8502 7.76953 20.4902" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M14.0402 13.8608C14.0402 14.7608 13.3202 15.5108 12.3902 15.5108C11.4902 15.5108 10.7402 14.7908 10.7402 13.8608C10.7402 12.9308 12.4202 10.8008 12.4202 10.8008C12.4202 10.8008 14.0402 12.9608 14.0402 13.8608Z" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16.26 23.8198C16.26 24.7198 15.54 25.4698 14.61 25.4698C13.71 25.4698 12.96 24.7498 12.96 23.8198C12.96 22.9198 14.61 20.7598 14.61 20.7598C14.61 20.7598 16.26 22.9198 16.26 23.8198Z" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M23.4899 13.1698L19.1699 8.33984L20.5799 5.33984L23.4899 5.96984" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M19.4699 7.71042L17.7599 6.90042C17.0099 6.54042 16.1399 6.48042 15.3599 6.78042L12.8999 7.65042C12.3599 7.83042 11.7599 7.56042 11.5799 7.02042C11.3999 6.48042 11.6999 5.91042 12.2099 5.70042L14.6699 4.83042C15.9899 4.38042 17.3999 4.44042 18.6599 5.04042L20.3399 5.85042" stroke="#82201E" stroke-miterlimit="10"/>
  </svg>
</template>
