export const useFavorites = () => {

  const cookieData = useCookie('vhs_fav');
  const favorites = useState('vhs_fav', () => (toRaw(cookieData.value) || []));
  const cars = useState('vhs_fav_cars',() => []);

  const backend = useBackend();

  const carsIds = computed(() => {

    favorites.value = favorites.value.filter(i => i);
    if (!favorites) return [];

    const ids = favorites.value.join(",");

    if (!ids) return [];

    return ids;
  })

  const count = computed(() => cars.value.length);

  const loadCars = async () => {
    if(!carsIds.value.length) {
      cars.value = [];
      return;
    }

    const availableCars = await backend.getCar(carsIds.value, false);
    if (availableCars.data && availableCars.data?.pop === undefined) {
      availableCars.data = [availableCars.data]
    }

    const tmpCars = [];
    cars.value = [];
    carsIds.value.split(",").forEach(id => {
      const car = availableCars.data.find(i => i.group_id === id);
      if(car) tmpCars.push(car);
    })

    cars.value = tmpCars;
  };

  const add = async (car) => {
    if (favorites.value.indexOf(car.group_id) === -1) {
      favorites.value.unshift(car.group_id);
      cookieData.value = favorites.value;

      useAnalytics().analyticsLayer({
        'event': 'add_to_compare',
        'brand': car.brandName,
        'model': car.modelName,
        'body': car.bodyType,
        'product_id': car.group_id
      })

      await loadCars();

    }
  }

  const remove = async (car) => {
    favorites.value = favorites.value.filter(id => id !== car.group_id);
    cookieData.value = favorites.value;
    await loadCars()
  }

  const isSet = (car) => favorites.value.indexOf(car.group_id) !== -1

  return {
    loadCars,
    add,
    remove,
    isSet,
    cars,
    count
  }
}
