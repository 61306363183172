<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icon_rocznik 1">
<path id="Vector" d="M17.4659 6.24023H19.2201C19.4707 6.24023 19.6796 6.44909 19.6796 6.69973V18.9807C19.6796 19.2314 19.4707 19.4402 19.2201 19.4402H5.01902C4.76841 19.4402 4.55957 19.2314 4.55957 18.9807V6.69973C4.55957 6.44909 4.76841 6.24023 5.01902 6.24023H6.89858" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M9.59961 6.24023H14.6396" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_3" d="M4.55957 9.11914H19.6796" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_4" d="M8.16016 4.80078V7.44078" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_5" d="M16.0801 4.80078V7.44078" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_6" d="M8.02878 11.2793H7.8106C7.7383 11.2793 7.67969 11.3379 7.67969 11.4102V11.6284C7.67969 11.7007 7.7383 11.7593 7.8106 11.7593H8.02878C8.10108 11.7593 8.15969 11.7007 8.15969 11.6284V11.4102C8.15969 11.3379 8.10108 11.2793 8.02878 11.2793Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_7" d="M10.6694 11.2793H10.4512C10.3789 11.2793 10.3203 11.3379 10.3203 11.4102V11.6284C10.3203 11.7007 10.3789 11.7593 10.4512 11.7593H10.6694C10.7417 11.7593 10.8003 11.7007 10.8003 11.6284V11.4102C10.8003 11.3379 10.7417 11.2793 10.6694 11.2793Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_8" d="M13.5493 11.2793H13.3311C13.2588 11.2793 13.2002 11.3379 13.2002 11.4102V11.6284C13.2002 11.7007 13.2588 11.7593 13.3311 11.7593H13.5493C13.6216 11.7593 13.6802 11.7007 13.6802 11.6284V11.4102C13.6802 11.3379 13.6216 11.2793 13.5493 11.2793Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_9" d="M16.1889 11.2793H15.9708C15.8985 11.2793 15.8398 11.3379 15.8398 11.4102V11.6284C15.8398 11.7007 15.8985 11.7593 15.9708 11.7593H16.1889C16.2612 11.7593 16.3198 11.7007 16.3198 11.6284V11.4102C16.3198 11.3379 16.2612 11.2793 16.1889 11.2793Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_10" d="M8.02878 14.1602H7.8106C7.7383 14.1602 7.67969 14.1895 7.67969 14.2256V14.3347C7.67969 14.3709 7.7383 14.4002 7.8106 14.4002H8.02878C8.10108 14.4002 8.15969 14.3709 8.15969 14.3347V14.2256C8.15969 14.1895 8.10108 14.1602 8.02878 14.1602Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_11" d="M10.9087 14.1602H10.6905C10.6182 14.1602 10.5596 14.1895 10.5596 14.2256V14.3347C10.5596 14.3709 10.6182 14.4002 10.6905 14.4002H10.9087C10.981 14.4002 11.0396 14.3709 11.0396 14.3347V14.2256C11.0396 14.1895 10.981 14.1602 10.9087 14.1602Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_12" d="M13.5493 14.1602H13.3311C13.2588 14.1602 13.2002 14.1895 13.2002 14.2256V14.3347C13.2002 14.3709 13.2588 14.4002 13.3311 14.4002H13.5493C13.6216 14.4002 13.6802 14.3709 13.6802 14.3347V14.2256C13.6802 14.1895 13.6216 14.1602 13.5493 14.1602Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_13" d="M16.1889 14.1602H15.9708C15.8985 14.1602 15.8398 14.1895 15.8398 14.2256V14.3347C15.8398 14.3709 15.8985 14.4002 15.9708 14.4002H16.1889C16.2612 14.4002 16.3198 14.3709 16.3198 14.3347V14.2256C16.3198 14.1895 16.2612 14.1602 16.1889 14.1602Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_14" d="M8.02878 16.5605H7.8106C7.7383 16.5605 7.67969 16.6192 7.67969 16.6915V16.9096C7.67969 16.9819 7.7383 17.0405 7.8106 17.0405H8.02878C8.10108 17.0405 8.15969 16.9819 8.15969 16.9096V16.6915C8.15969 16.6192 8.10108 16.5605 8.02878 16.5605Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_15" d="M10.9087 16.5605H10.6905C10.6182 16.5605 10.5596 16.6192 10.5596 16.6915V16.9096C10.5596 16.9819 10.6182 17.0405 10.6905 17.0405H10.9087C10.981 17.0405 11.0396 16.9819 11.0396 16.9096V16.6915C11.0396 16.6192 10.981 16.5605 10.9087 16.5605Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_16" d="M13.5493 16.5605H13.3311C13.2588 16.5605 13.2002 16.6192 13.2002 16.6915V16.9096C13.2002 16.9819 13.2588 17.0405 13.3311 17.0405H13.5493C13.6216 17.0405 13.6802 16.9819 13.6802 16.9096V16.6915C13.6802 16.6192 13.6216 16.5605 13.5493 16.5605Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
</template>
