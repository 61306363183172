<script setup>
const props = defineProps({
  label: String,
  isLiczak: {
    type: Boolean,
    value: false
  },
  price: [String, Number],
  discounted: Boolean,
  offerClient: String
})
</script>

<template>
<div class="flex flex-col font-extralight text-xs">
  <div>{{ label }}</div>
  <div class="text-sm font-light" :class="[{'line-through': props.discounted}]">
    <EffectPrice :is-liczak="isLiczak" :offer-client="offerClient" :value="price" /> zł 
    <EffectNetGrossIndicator :offer-client="offerClient" />
  </div>
</div>
</template>
