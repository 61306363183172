<script setup>

</script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9401 23.3685C15.6691 23.3685 16.2601 22.7775 16.2601 22.0485C16.2601 21.3195 15.6691 20.7285 14.9401 20.7285C14.2111 20.7285 13.6201 21.3195 13.6201 22.0485C13.6201 22.7775 14.2111 23.3685 14.9401 23.3685Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M16.2598 22.0488L19.2898 22.0788" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M13.62 22.0788L10.71 22.0488" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M15 20.6998V8.33984" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M10.8604 8.33984H19.0804" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M10.3504 25.2595H7.86043C7.62043 25.2595 7.44043 25.0795 7.44043 24.8395V19.4395C7.44043 19.1995 7.62043 19.0195 7.86043 19.0195H10.3504C10.5904 19.0195 10.7704 19.1995 10.7704 19.4395V24.8095C10.7704 25.0495 10.5904 25.2595 10.3504 25.2595Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M22.1405 25.1983H19.6505C19.4105 25.1983 19.2305 25.0183 19.2305 24.7783V19.4083C19.2305 19.1683 19.4105 18.9883 19.6505 18.9883H22.1405C22.3805 18.9883 22.5605 19.1683 22.5605 19.4083V24.7783C22.5905 25.0183 22.3805 25.1983 22.1405 25.1983Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M20.7601 11.8798L18.4801 10.8598C18.2701 10.7698 18.1801 10.4998 18.2701 10.2898L20.4901 5.36975C20.5801 5.15975 20.8501 5.06975 21.0601 5.15975L23.3401 6.17975C23.5501 6.26975 23.6401 6.53975 23.5501 6.74975L21.3301 11.6698C21.2101 11.8798 20.9701 11.9698 20.7601 11.8798Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M8.97006 11.4891L6.69006 10.4691C6.48006 10.3791 6.39006 10.1091 6.48006 9.89913L8.70006 4.97913C8.79006 4.76913 9.06006 4.67913 9.27006 4.76913L11.5501 5.78913C11.7601 5.87913 11.8501 6.14913 11.7601 6.35913L9.54006 11.2791C9.42006 11.4891 9.18006 11.6091 8.97006 11.4891Z" stroke="#82201E" stroke-miterlimit="10"/>
  </svg>

</template>
