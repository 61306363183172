<script setup>

</script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.78027 11.4316V18.2716" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M3.78027 14.6108L6.27027 14.5508" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M6.4502 11.4316V19.3216" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M8.3102 11.4316H6.4502" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M9.75055 9.99023L8.31055 11.4302" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.7 9.99023H9.75" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M19.1102 11.4302L17.7002 9.99023" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M21.0603 11.4316H19.1104" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M21.0605 14.1016V11.4316" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.5905 14.1016H21.0605" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.5898 12.0918V14.1018" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M9.7502 19.3203H6.4502" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M12.75 21.5403L9.75 19.3203" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M20.64 21.3906L12.75 21.5406" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M20.6406 18.75V21.39" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.7706 18.75H20.6406" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.7705 20.61V18.75" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.0205 20.6113H22.7705" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.0198 12.0918H22.5898" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M14.9404 9.78094V8.46094" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M12 9.78094V8.46094" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M9.99023 8.46094H16.8902" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.0205 12.0918C25.4105 12.7218 26.1905 14.2218 26.2205 16.2918C26.2505 18.4518 25.4105 19.9818 25.0205 20.6118" stroke="#82201E" stroke-miterlimit="10" stroke-linecap="round"/>
  </svg>

</template>
