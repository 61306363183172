<script setup>
const leasing = useLeasingParams();
const {onWindowEvent} = useWindowEvents()
const calculator = useCalculator();

const favs = useFavorites();
const isDragging = useState('slideControlDragging', () => false)
const waitForChange = ref(false);

const props = defineProps({
  car: Object,
  thin: Boolean,
  imageOnScroll: {
    type: Number,
    default: null
  },
  getOffer: Boolean,
  noButton: Boolean,
  noTop: Boolean,
  noAdjust: Boolean,
  chooseView: Boolean,
  offerView: Boolean
})

const emits = defineEmits(['adjust'])

const currentCar = computed(() => props.car);
const labelPrice = computed(() => {return currentCar.value.state === 'Nowy' ? 'Cena katalogowa' : 'Cena w internecie'});
const client = computed(() => leasing.refParams.value.client);
const currentPrice = computed(() => {
  if (client.value === 'entrepreneur') {
    return currentCar.value.price;
  } else {
    return currentCar.value.consumer_price ?? currentCar.value.price;
  }
});
const ow = computed(() => currentCar.value ? currentPrice.value * (leasing.refParams.value.initialFee / 100) : 0);
const rv = computed(() => currentCar.value ? currentPrice.value * (leasing.refParams.value.repurchase / 100) : 0);
const installment = computed(() => currentCar.value.installment)

const showImage = ref(false);

const addOrRemoveFromFavorites = (add, car) => {
  !add ? favs.remove(car) : favs.add(car);
}

if (props.imageOnScroll) {
  onWindowEvent('scroll', () => {
    showImage.value = window.scrollY >= props.imageOnScroll
  })
}

const recalculateInstallment = () => {
  waitForChange.value = true;
  calculator.getInstallment({data: currentCar.value}, true);
}

leasing.onChange( () =>  recalculateInstallment());
onMounted(() => calculator.getInstallment({data: currentCar.value}, true));

watch(() => currentCar.value.installment, () => { waitForChange.value = false; });

const route = useRoute();
const isDigitalOrder = computed(()=> {
  return route.name === 'transaction-form';
})
</script>

<template>
  <div class="rounded-lg overflow-hidden shadow-box select-none bg-white pointer-events-auto relative"
       :class="{'min-w-[300px]':!thin, 'min-w-[280px]':thin}"
       @dragstart.prevent=""
  >
    <div class="font-extralight transition-all" :class="[{'-mt-72':imageOnScroll && !showImage, 'hidden':noTop}]">
      <template v-if="noButton">
        <CarImageBox :offer-view="offerView" :choose-view="chooseView" :car="car" class='mb-2' />
      </template>
      <template v-else>
        <NuxtLinkConditional :can="!isDragging" :to="getOffer ? {name: 'decide', params: {id: car?.group_id}} : {name: 'offer', params: {id: car?.group_id}}">
          <CarImageBox :offer-view="offerView" :choose-view="chooseView" :car="car" class='mb-2' />
        </NuxtLinkConditional>
      </template>
      <div class="px-4 pb-2">
      <div ref="refId"
           class="flex justify-between overflow-hidden"
      >
        <div v-if="!offerView && !chooseView && car?.group_id" class="text-sm"><EffectCopy>{{ car?.group_id }}</EffectCopy></div>
        <div v-if="!offerView" :class="{'absolute top-0 right-0 px-4 font-normal pt-1.5 bg-white rounded-bl-lg z-10' : chooseView}">
          <ButtonToggle class="text-xs text-azure"
                        :toggled="favs.isSet(car)"
                        @toggle="(toggled) => addOrRemoveFromFavorites(toggled, car)"
          >
            <template v-slot:default>
              <div v-if="!isDigitalOrder" class="flex gap-1 items-center">
                <IconCompare class="!w-4 !h-4" thin />
                Porównaj
              </div>
            </template>
            <template v-slot:toggled>
              <div class="flex gap-1 items-center">
                <IconCompare class="!w-4 !h-4" thin />
                W porównaniu
              </div>
            </template>
          </ButtonToggle>
        </div>
      </div>
      <div :class="chooseView ? 'min-h-16 whitespace-normal' : 'whitespace-nowrap'" class="text-lg font-normal mt-2 text-ellipsis overflow-hidden border-b-gray-400 border-b pb-2.5">
        <span :class="showImage && offerView ? '' : 'min-h-16'" class="block">{{ car?.versionName }} </span>
        <div v-if="!isDigitalOrder" class="flex justify-between mt-1 items-center">
          <p class="flex text-xs font-extralight items-center">
            <IconCarProductedDate/>
            <span class="ml-1">{{ car?.productionYear }} </span>
          </p>
          <p class="flex text-xs font-extralight items-center">
            <IconCarMileage/>
            <span class="ml-1">{{ priceFormat(car?.mileage) }} km</span>
          </p>
        </div>
      </div>
      </div>
    </div>
    <div class="px-4 pt-3 pb-6 font-extralight">
      <div class="space-y-2.5">
        <div>
          <div>
            <div class="col-span-2 grid grid-cols-2 gap-2.5 items-center">
              <div>
                <div class="text-xs">Twoja rata
                  <EffectNetGrossIndicator />
                </div>
                <div class="text-azure ">
                  <EffectOdomoeter class="text-4xl font-normal" :value="installment" :loading="waitForChange"/>
                  <span>zł</span>
                </div>
              </div>
            </div>
          </div>
          <div ref="refPrices" class="grid grid-cols-2 gap-2.5 py-2.5">
            <CarCardPrice v-if="currentCar?.catalogPrice" :label="labelPrice" :price="currentCar?.catalogPrice" discounted />
            <CarCardPrice label="Cena dla Ciebie" :price="currentPrice" />
            <CarCardPrice label="Opłata wstępna" :price="ow" />
            <CarCardPrice label="Wartość wykupu" :price="rv" />
          </div>
          <div v-if="!noButton">
            <template v-if="!getOffer">
              <ButtonRounded :href="{name: 'offer', params: {id: currentCar?.group_id}}">Przejdź do oferty</ButtonRounded>
            </template>
            <template v-else>
              <ButtonRounded color="azure" :href="{name: 'decide', params: {id: currentCar?.group_id}}">Pobierz ofertę</ButtonRounded>
            </template>
          </div>
        </div>
        <div>
          <div ref="refIcons" class="grid grid-cols-2 gap-2.5 pt-1 overflow-hidden">
            <div v-if="currentCar.fuelType" class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarFuel class="w-6 h-6 min-w-6" />
              </div>
              <div class="max-h-6">{{ currentCar.fuelType }}</div>
            </div>
            <div v-if="currentCar.horsePower" class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarPower class="w-6 h-6 min-w-6" />
              </div>
              <div class="max-h-6">{{ currentCar.horsePower }}
                <span class="uppercase">KM</span>
              </div>
            </div>
            <div v-if="currentCar.gearboxType" class="text-xs lowercase flex items-center">
              <IconCarGearBox class="w-6 h-6 min-w-6" />
              <div class="max-h-8">{{ currentCar.gearboxType }}</div>
            </div>
            <div v-if="currentCar.driveType" class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarDrive class="w-6 h-6 min-w-6" />
              </div>
              <div class="max-h-6">{{ currentCar.driveType }}</div>
            </div>
            <div v-if="currentCar.color || currentCar.paint" class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarPaint class="w-6 h-6 min-w-6"/>
              </div>
              <div class="h-11 overflow-hidden text-ellipsis flex items-center">
                <div>{{ currentCar.color }}</div>
                <div class="normal-case">{{ currentCar.paint }}</div>
              </div>
            </div>
            <div v-if="currentCar.seats" class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarSeats class="w-6 h-6 min-w-6" />
              </div>
              <div class="max-h-6">{{ currentCar.seats }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-if="chooseView || offerView" class="text-xs font-normal text-center w-full py-1 bg-[#F3F4F6]">ID samochodu: <EffectCopy>{{ car?.group_id }}</EffectCopy></div>
  </div>
</template>
