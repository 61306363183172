<script setup>

</script>

<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1401 11.7907C19.1401 11.7907 20.5201 10.0507 20.5201 8.1307C20.5201 6.1807 18.4501 4.4707 15.9301 4.4707C10.1101 4.4707 5.37012 9.1807 5.37012 15.0307C5.37012 17.5807 6.27012 20.0407 8.13012 21.9007M9.03012 17.7607C8.52012 17.7607 8.10012 17.3407 8.10012 16.8307C8.10012 16.3207 8.52012 15.9007 9.03012 15.9007C9.54012 15.9007 9.96012 16.3207 9.96012 16.8307C9.96012 17.3407 9.54012 17.7607 9.03012 17.7607ZM14.0701 7.6507C15.0901 7.6507 15.9001 8.4607 15.9001 9.4807C15.9001 10.5007 15.0901 11.3107 14.0701 11.3107C13.0501 11.3107 12.2401 10.5007 12.2401 9.4807C12.2401 8.4907 13.0801 7.6507 14.0701 7.6507ZM9.96012 11.3407C10.7101 11.3407 11.3401 11.9707 11.3401 12.7207C11.3401 13.4707 10.7101 14.1007 9.96012 14.1007C9.21012 14.1007 8.58012 13.4707 8.58012 12.7207C8.58012 11.9407 9.21012 11.3407 9.96012 11.3407Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M16.8305 20.4899C20.2205 17.0699 24.6305 11.4599 24.6305 10.3799C24.6305 10.3199 24.6005 10.2599 24.5705 10.2299C24.5405 10.1999 24.4805 10.1699 24.4205 10.1699C23.3405 10.1699 17.9405 14.7899 14.5205 18.1799L16.8305 20.4899Z" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M16.8301 24.1805C20.1001 23.7005 21.8701 21.2405 21.8701 19.1405C21.8701 18.6005 21.7501 17.7905 21.4201 17.3105" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M13.6204 19.1406C12.2404 19.1406 11.4004 19.5306 10.8604 20.0706C9.87039 21.0606 9.12039 24.1206 7.65039 25.5606C9.60039 25.5606 11.1604 25.5606 11.7904 25.5606C14.1604 25.5606 15.9304 24.0606 15.9304 21.4206" stroke="#82201E" stroke-miterlimit="10"/>
    <path d="M13.1704 23.25L10.8604 25.56" stroke="#82201E" stroke-miterlimit="10"/>
  </svg>

</template>
