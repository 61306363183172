<script setup>

import { v4 as uuidv4 } from 'uuid';

const slots = useSlots();
const uniqueId = "copy_" + uuidv4();

const props = defineProps({
  stringToCopy: String,
})

const selectAllTextAndCopy = async () => {
  const htmlElement = document.querySelector("#" + uniqueId);
  const data = props.stringToCopy || htmlElement.textContent;

  await navigator.clipboard.writeText(data);

  const rect = htmlElement.getBoundingClientRect();

  const popup = document.createElement("div");
  popup.innerHTML = "Skopiowano"
  popup.classList.add("tooltip");
  popup.style.left = rect.x + "px";
  popup.style.top = window.scrollY + (rect.top - 30) + "px";
  popup.style.zIndex = "9999";
  popup.addEventListener("transitionend", () => {
    const parent = popup.parentNode;
    if (!parent) return;
    parent.removeChild(popup);
  })
  setTimeout(() => {
    popup.classList.add("tooltip-hidden");
  }, 1000);
  document.body.appendChild(popup);
}

</script>

<template>
  <ClientOnly>
    <span @click="selectAllTextAndCopy" :id="uniqueId" class="cursor-copy"><slot /></span>
    <template #fallback>
      <span><slot /></span>
    </template>
  </ClientOnly>
</template>
